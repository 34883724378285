import React from "react";
import "./styles.css";
import profile from "./img/profile.jpg";
export default function About() {
  return (
    <div className="About">
      <div className="text-center col-xl-12">
        <img
          src={profile}
          alt="myimage"
          className="profile-logo mx-auto d-block mt rounded-circle"
        />
        <p className="text-white  text-center  mt-5 about-saket">
        I am a cybersecurity Graduate Student at Concordia University, Montreal with expertise in a wide range of security tools and technologies. I am proficient in using Burp Suite, NMAP, Metasploit, and Wireshark for vulnerability assessment and penetration testing. I have a deep understanding of networking concepts and protocols such as the OSI model, TCP/IP protocols, routing protocols, NAT, and subnetting. I am also skilled in shell scripting and have a good knowledge of Python. I am familiar with various operating systems including Kali Linux, Debian, CentoOS, Ubuntu, Parrot, Windows, and macOS. Additionally, I have experience with EDR and SEIM tools such as Crowdstrike Falcon, and malware analysis using Flare VM. I am well-versed in various security frameworks such as ISO27001, MITRE ATTACK, SANS, HIPAA, GDPR, and PCI-DSS.
        </p>
      </div>
    </div>
  );
}
